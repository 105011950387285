import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import Home from './components/Home';
import Ddemo from './components/Ddemo';
import Shop from './shop/Shop';
import ShopHome from './shop/ShopHome';
import ItemDetail from './shop/ItemDetail';
import Auth from './auth/Auth';
import reportWebVitals from './reportWebVitals';
import { Routes, Route, BrowserRouter } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import Dtest from './components/Dtest';
import { CartContext, CartProvider } from './shop/CartContext';


const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

root.render(
  // <React.StrictMode>
  <BrowserRouter>
  <Routes>

    <Route path="/" element={<App />}>
      <Route path="/" element={<Home />}></Route>
      <Route path="/d3demo" element={<Ddemo />}></Route>
      <Route path="/d3test" element={<Dtest />}></Route>
      <Route path="/shop" element={ <CartProvider><Shop /></CartProvider> } >
        <Route path="/shop" element={<ShopHome />}></Route>
        <Route path="/shop/:id" element={<ItemDetail  />}></Route>
      </Route>
      <Route path="/auth" element={ <Auth /> } >
        {/* <Route path="/auth" element={<ShopHome />}></Route> */}
        {/* <Route path="/auth/:id" element={<ItemDetail  />}></Route> */}
      </Route>
    </Route>

  </Routes>
  </BrowserRouter>
  // </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

