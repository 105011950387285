import React, { FC, useContext, useState, useEffect } from 'react';
import { Outlet, Link } from 'react-router-dom';
import CartItem2 from './CartItem2';
import { CartContext } from './CartContext';

interface CartProps {
    item?: any;
    subtitle?: string;
    cart?: any;
    setCart?: any;
    bListF?: any;
}

const CartItem: FC<CartProps> = () => {
    const { aList, setAList, bList, setBList, sum, setSum } = useContext(CartContext);

    const bitemList: any = bList.map((item: any) => <CartItem2 item={item} key={item.id} />);

    return (
        <div className='container ' >
            <div className=' list-group list-group-flush' >
                <div className='list-group-item d-flex justify-content-between px-0 ' >
                    <div className='my-1 col-sm-2 col-2 fw-bold text-muted text-opacity-25 ' style={{ fontSize: '0.8rem' }} ></div>
                    {/* <Link to={`/shop/${props.item.id}`} className='my-2 fs-6 ' >{props.item.title}</Link> */}
                    <div className='my-1 col-sm-2 col-2 fw-bold text-end ' style={{ fontSize: '0.8rem' }} >单价：元</div>
                    <div className='my-1 col-sm-2 col-2 fw-bold text-center ' style={{ fontSize: '0.8rem' }} >数量：</div>
                    <div className='my-1 col-sm-2 col-2 fw-bold text-end text-info ' style={{ fontSize: '0.8rem' }} >小计：元</div>
                    {/* <div className='my-1' >{props.item.description}</div> */}
                </div>
                {bitemList}
                <div className='list-group-item d-flex justify-content-between px-0 ' >
                    <div className='my-1 col-2 fs-6 text-muted text-opacity-25 ' >合计：</div>
                    {/* <Link to={`/shop/${props.item.id}`} className='my-2 fs-6 ' >{props.item.title}</Link> */}
                    <div className='my-1 col-2  ' ></div>
                    <div className='my-1 col-2  ' ></div>
                    <div className='my-1 col-2 text-primary fw-bold text-end ' >{sum.toFixed(2)}</div>
                    {/* <div className='my-1' >{props.item.description}</div> */}
                </div>
            </div>
            <div className = "btn btn-light btn-lg text-info mt-3" >进入支付</div>
        </div>
    )
};

export default CartItem;