import React, { useState, useEffect, useContext } from 'react';
import { Outlet, Link } from 'react-router-dom';
import ItemShort from './ItemShort';
import { BeatLoader } from "react-spinners";
import { CartContext } from './CartContext';
import CartItem from './CartItem';


export default function ShopHome() {
    const [item, setItem] = useState({
        id: null,
        title: '',
        price: '',
        category: '',
        description: '',
        image: ''
    });
    // const [loading, setLoading] = useState(true);
    const { aList, setAList, bList, setBList, loading1, setLoading1, sum, setSum} = useContext(CartContext);

    const itemList: any = aList.map((item: any) => <ItemShort item={item} key={item.id}  />);

    useEffect(()=>{

    }, []);

    return (
        <div className='my-2 ' >
            <CartItem />
            <div className='fs-3 my-3' >商品列表如下：</div>
            <div className="row row-cols-1 row-cols-sm-2 row-cols-md-3 row-cols-md-4 g-4" >
                {itemList}
            </div>
            <BeatLoader loading={loading1} cssOverride={{ marginTop: '2rem', marginBottom: '2rem', }} />
        </div>
    )
};