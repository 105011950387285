import React, { FC, useContext, useState } from 'react';
import { Outlet, Link } from 'react-router-dom';
import { CartContext } from './CartContext';


interface CartProps {
    item: any;
    subtitle?: string;
    cart?: any;
    setCart?: any;
    bListF?: any;
}

const CartItem2: FC<CartProps> = (props) => {
    const { aList, bList, setBList, sum, setSum } = useContext(CartContext);

    const bListF = () => {
        let itList: any = [];
        var cartkey = Object.keys(sessionStorage)
        for (var key of cartkey) {
            if (sessionStorage[key as keyof typeof sessionStorage] === 0) {
            } else {
                for (let it of aList) {
                    if (key === `id${it['id']}`) {
                        const num = { number: sessionStorage[key as keyof typeof sessionStorage] };
                        const sub = { sub: sessionStorage[key as keyof typeof sessionStorage] * it['price'] }
                        it = Object.assign({}, it, num, sub);
                        itList.push(it);
                        
                    }
                }
            }
        }
        let k = 0;
        for (let it of itList) {
            k = k + it['sub']
        };
        setSum(k);
        setBList(itList);
    }

    const addCart = () => {
        var it = Number(sessionStorage.getItem(`id${props.item.id}`));
        if (isNaN(it)) {
            it = 1;
        } else {
            it = it + 1;
        };
        sessionStorage.setItem(`id${props.item.id}`, String(it));
        bListF();
    };

    const minusCart = () => {
        var it = Number(sessionStorage.getItem(`id${props.item.id}`));
        if (isNaN(it)) {

        } else {
            if (it > 0) {
                it = it - 1;
                if (it === 0) {
                    sessionStorage.removeItem(`id${props.item.id}`)
                }else{
                    sessionStorage.setItem(`id${props.item.id}`, String(it));
                }
            }
        };
        bListF();
    }

    return (
        <div className='list-group-item d-flex justify-content-between align-items-center px-0 ' >
            <div className='my-0  col-sm-2 col-2 text-muted text-opacity-25 ' >
            <Link to={`/shop/${props.item.id}`} className='my-2 fs-6 ' >
                <img src={props.item.image} className="img-thumbnail py-0 px-0 my-0 mx-0 " style={{ width: 'auto', maxWidth: '3rem', maxHeight: '3rem' }} alt="..."></img>
            </Link>
            </div>
            <div className='my-1 col-sm-2 col-2 text-end  ' style={{ fontSize: '0.8rem' }} >{props.item.price}</div>
            <div className='my-1 col-sm-2 col-3 text-end d-flex justify-content-between align-items-center ' style={{ fontSize: '0.8rem' }} >
                <button className='btn btn-primary btn-sm  ' onClick={() => minusCart()}  >-</button>
                <div className='my-1 col-sm-3 col-3 text-end '>
                    {props.item.number}
                </div>
                <button className='btn btn-primary btn-sm  ' onClick={() => addCart()} >+</button>
            </div>
            <div className='my-1 col-sm-2 col-2 text-end text-info ' style={{ fontSize: '0.8rem' }} >{props.item.sub.toFixed(2)}</div>
        </div>
    )
};

export default CartItem2;