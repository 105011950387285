import React, { FC, useContext, useState, useEffect } from 'react';
import { Outlet, Link } from 'react-router-dom';
import { CartContext } from './CartContext';


interface TitleProps {
    item: any;
    subtitle?: string;
    cart?: any;
    setCart?: any;
    bListF?: any;
}

const ItemShort: FC<TitleProps> = (props) => {
    const { aList, setAList, bList, setBList, sum, setSum } = useContext(CartContext);

    const addCart = () => {
        var it = Number(sessionStorage.getItem(`id${props.item.id}`));
        if (isNaN(it)) {
            it = 1;
        } else {
            it = it + 1;
        };
        sessionStorage.setItem(`id${props.item.id}`, String(it));

        let itList: any = [];
        var cartkey = Object.keys(sessionStorage)
        for (var key of cartkey) {
            if (sessionStorage[key as keyof typeof sessionStorage] === 0) {
            } else {
                for (let it of aList) {
                    if (key === `id${it['id']}`) {
                        const num = { number: sessionStorage[key as keyof typeof sessionStorage] };
                        const sub = { sub: sessionStorage[key as keyof typeof sessionStorage] * it['price'] }
                        it = Object.assign({}, it, num, sub);
                        itList.push(it);
                    }
                }
            }
        }
        let k = 0;
        for (let it of itList) {
            k = k + it['sub']
        };
        setSum(k);
        setBList(itList);
    };

    return (
        <div className='col' >
            <div className='container fs-1' >

            </div> 
            <div className='card' >
                <div className='d-flex justify-content-center' >
                    <Link to={`/shop/${props.item.id}`} className='my-2 fs-6 ' >
                        <img src={props.item.image} className="card-img-top px-2 my-2 " style={{ width:'auto',maxWidth:'100%' ,maxHeight:'10rem'}} alt="..." ></img>
                    </Link>
                </div>
                <div className="card-body">
                    <div className='card-title my-1 fs-6 text-muted text-opacity-25 ' >{props.item.id} 号商品：</div>
                    <Link to={`/shop/${props.item.id}`} className='my-2 fs-6 ' >{props.item.title}</Link>
                    <div className='my-1 text-info ' >价格：{props.item.price} 元</div>
                    <button className='btn btn-primary btn-sm' onClick={() => addCart()} >放入购物车</button>
                    {/* <div className='my-1' >{props.item.description}</div> */}
                </div>
            </div>
        </div>
    )
};

export default ItemShort;