import { type } from '@testing-library/user-event/dist/type';
import React, { useState, useEffect, createContext } from 'react';


type CartContextType = {
  aList: any
  setAList: React.Dispatch<React.SetStateAction<any>>,
  bList: any
  setBList: React.Dispatch<React.SetStateAction<any>>,
  loading1: boolean,
  setLoading1: React.Dispatch<React.SetStateAction<boolean>>,
  sum: number,
  setSum: React.Dispatch<React.SetStateAction<number>>,
}

const iCartContextState = {
  aList: [],
  setAList: () => {},
  bList: [],
  setBList: () => {},
  loading1: true,
  setLoading1: () => {},
  sum: 0,
  setSum: () => {},
}

export const CartContext = createContext<CartContextType>(iCartContextState)

export const CartProvider = (props:any) => {
  // ALL PRODUCTS FULL LIST
  const [aList, setAList] = useState([]);
  // CART PRODUCTS FULL+EXTRA LIST
  const [bList, setBList] = useState([]);
  const [loading1, setLoading1] = useState(true);
  const [sum, setSum] = useState(0);

  // useEffect(() => {
  //   fetch('https://fakestoreapi.com/products')
  //       .then(res => res.json())
  //       .then(json => setAList(json)).then(() => setLoading(false));

  //   let itList: any = [];
  //   var cartkey = Object.keys(sessionStorage)
  //   for (var key of cartkey) {
  //       if (sessionStorage[key as keyof typeof sessionStorage] === 0) {
  //       } else {
  //           for (let it of aList) {
  //               if (key === `id${it['id']}`) {
  //                   const num = { number: sessionStorage[key as keyof typeof sessionStorage] };
  //                   const sub = { sub: sessionStorage[key as keyof typeof sessionStorage] * it['price'] }
  //                   it = Object.assign({}, it, num, sub);
  //                   itList.push(it);
  //                   console.log(itList);
  //               }
  //           }
  //       }
  //   }
  //   setBList(itList);

  //   // let k = 0;
  //   // for (let it of bList) {
  //   //     k = k + it['sub']
  //   // };
  //   // setSum(k);

  //   let k = 0;
  //   var cartkey2 = Object.keys(sessionStorage)
  //   for (var key2 of cartkey2) {
  //       if (sessionStorage[key2 as keyof typeof sessionStorage] === 0) {
  //       } else {
  //           for (let it of aList) {
  //               if (key2 === `id${it['id']}`) {
  //                   k = k + sessionStorage[key2 as keyof typeof sessionStorage] * it['price'];
  //                   console.log(k);
  //               }
  //           }
  //       }
  //   }
  //   setSum(k);
  // }, []);

  return (
    <CartContext.Provider value={{ aList, setAList, bList, setBList, loading1, setLoading1, sum, setSum}}>
      {props.children}
    </CartContext.Provider>
  );
};
