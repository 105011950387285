import React, { useState, useEffect, useContext } from 'react';
import { Outlet, Link } from 'react-router-dom';
import { CartContext } from './CartContext';

export default function Shop() {
    const { aList, setAList, bList, setBList, loading1, setLoading1, sum, setSum} = useContext(CartContext);

    useEffect(() => {
        fetch('https://fakestoreapi.com/products')
            .then(res => res.json())
            .then(json => setAList(json)).then(() => setLoading1(false)).then(()=>{
    });
    }, []);

    useEffect(() => {
        let itList: any = [];
        var cartkey = Object.keys(sessionStorage)
        for (var key of cartkey) {
            if (sessionStorage[key as keyof typeof sessionStorage] === 0) {
            } else {
                for (let it of aList) {
                    if (key === `id${it['id']}`) {
                        const num = { number: sessionStorage[key as keyof typeof sessionStorage] };
                        const sub = { sub: sessionStorage[key as keyof typeof sessionStorage] * it['price'] }
                        it = Object.assign({}, it, num, sub);
                        itList.push(it);
                    }
                }
            }
        }
        setBList(itList);
    
        // let k = 0;
        // for (let it of bList) {
        //     k = k + it['sub']
        // };
        // setSum(k);
    
        let k = 0;
        var cartkey2 = Object.keys(sessionStorage)
        for (var key2 of cartkey2) {
            if (sessionStorage[key2 as keyof typeof sessionStorage] === 0) {
            } else {
                for (let it of aList) {
                    if (key2 === `id${it['id']}`) {
                        k = k + sessionStorage[key2 as keyof typeof sessionStorage] * it['price'];
                    }
                }
            }
        }
        setSum(k);
    }, [aList]);

    return(
        <div className='container'>
            
            <div className='fw-bold fs-1 my-3 '>这是一个虚构的网店</div>
            <Outlet />
        </div>
    )
};