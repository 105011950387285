import React, { useState, useEffect } from 'react';
import { Outlet, Link } from 'react-router-dom';
import './App.css';


function App() {
  const queryParameters = new URLSearchParams(window.location.search)
  const code:any = queryParameters.get("code")
  const state:any = queryParameters.get("state")
  const [res, setRes] = useState('')

  useEffect(
    () => {
      // fetch(`https://api.weixin.qq.com/sns/oauth2/access_token?appid=wx8d7d675a5985a897&secret=776db9e36c6167f48c92bd33b3b1ff54&code=${code}&grant_type=authorization_code`)
      // .then(res => res.json())
      // .then(res => setRes(res))
      const formdata = new FormData();
      formdata.append("code",code)
      formdata.append("state",state)
      fetch('https://www.dataocean.club/wx/webaccesstoken/', {
        method: 'POST',
        mode:'cors',
        credentials:'include',
        body: formdata,
      }).then(res => res.json())
      .then(res => setRes(JSON.stringify(res)))
    }, []
  )

  return (
    <div className="container ">
      <div className='d-flex justify-content-end my-3 ' style={{fontSize: "1rem", backgroundColor:"#f8f9fa"}}>
        <div className='mx-2' >
          <a href="https://www.wangshengsh.com" style={{ height:"20px",lineHeight:"20px",margin: "0px 0px 0px 5px", color:"blue"}}>访问 React 网站</a>
        </div>
        <div className='mx-2' >
          <Link to="/shop" style={{ height:"20px",lineHeight:"20px",margin: "0px 0px 0px 5px", color:"blue"}}>虚构网店</Link>
        </div>
        
        <div className='mx-2' >
          <Link to="/" style={{ height:"20px",lineHeight:"20px",margin: "0px 0px 0px 5px", color:"blue"}}>回到首页</Link>
        </div>
      </div>

      <Outlet />

      <div>
      <p>Code: {code}</p>
      <p>State: {state}</p>
      <p>Res: {res}</p>
      </div>
      <div style={{fontSize: "1rem", display:"flex",flexDirection: "row",justifyContent: "center", alignItems: "center", width:"100%", padding:"20px 0", margin:"0 auto", backgroundColor:"#f8f9fa"}}>
        <a href="https://beian.miit.gov.cn/" style={{ height:"20px",lineHeight:"20px",margin: "0px 0px 0px 5px", color:"#939393"}}>沪ICP备2022005202号-1 &nbsp; </a>
      </div>

    </div>
  );
}

export default App;

