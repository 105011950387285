import React from "react";
import { Link } from "react-router-dom";


function Home() {



return (
    <div className="d-flex flex-column align-items-start my-5 ">
        <div className="fw-bold fs-3 mx-2 my-3 " >D3 demos:</div>
        <div><Link to="/d3demo" className="mx-2 my-2" > 可移动且互斥的方块 </Link></div>
        <div><Link to="/d3test" className="mx-2 my-2" > 可切换状态的关系图 </Link></div>
        <div className="fw-bold fs-3 mx-2 my-3 " >Authorization demos:</div>
        <div><Link to="/auth" className="mx-2 my-2" > 登录界面 </Link></div>
        {/* <div><Link to="/d3test" className="mx-2 my-2" > 可切换状态的关系图 </Link></div> */}



    </div>

);
}

export default Home;