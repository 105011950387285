import React, { useEffect } from "react";
import * as d3 from "d3";
import '../Dtest.css'


function Dtest() {

    useEffect(() => {
        // const height = Math.min(500, width * 0.6);

        function clamp(x: any, lo: any, hi: any) {
            return x < lo ? lo : x > hi ? hi : x;
        };

        const graph = ({
            nodes: Array.from({ length: 13 }, () => ({})),
            links: [
                { source: 0, target: 1 },
                { source: 1, target: 2 },
                { source: 2, target: 0 },
                { source: 1, target: 3 },
                { source: 3, target: 2 },
                { source: 3, target: 4 },
                { source: 4, target: 5 },
                { source: 5, target: 6 },
                { source: 5, target: 7 },
                { source: 6, target: 7 },
                { source: 6, target: 8 },
                { source: 7, target: 8 },
                { source: 9, target: 4 },
                { source: 9, target: 11 },
                { source: 9, target: 10 },
                { source: 10, target: 11 },
                { source: 11, target: 12 },
                { source: 12, target: 10 }
            ]
        })

        const svg = d3.select("svg");

        const link = svg
            .selectAll(".link")
            .data(graph.links)
            .join("line")
            .classed("link", true);

        const node = svg
            .selectAll(".node")
            .data(graph.nodes)
            .join("circle")
            .attr("r", 12)
            .classed("node", true)
            .classed("fixed", (d: any) => d.fx !== undefined);

        svg.node();

        const simulation = d3
            .forceSimulation()
            .nodes(graph.nodes)
            .force("charge", d3.forceManyBody())
            .force("center", d3.forceCenter(200, 600 / 2))
            .force("link", d3.forceLink(graph.links))
            .on("tick", tick);

        const drag: any = d3.drag()
            .on("start", dragstart)
            .on("drag", dragged);

        node.call(drag).on("click", click);

        function tick() {
            link
                .attr("x1", (d: any) => d.source.x)
                .attr("y1", (d: any) => d.source.y)
                .attr("x2", (d: any) => d.target.x)
                .attr("y2", (d: any) => d.target.y);
            node
                .attr("cx", (d: any) => d.x)
                .attr("cy", (d: any) => d.y);
        }

        function click(this: any, event: any, d: any) {
            delete d.fx;
            delete d.fy;
            d3.select(this).classed("fixed", false);
            simulation.alpha(1).restart();
        }

        function dragstart(this: any) {
            d3.select(this).classed("fixed", true);
        }

        function dragged(event: any, d: any) {
            d.fx = clamp(event.x, 0, 600);
            d.fy = clamp(event.y, 0, 600);
            simulation.alpha(1).restart();
        }

    }, []);


    return (
        <div>
            <div className="container">
                
                <div className="my-2 text-start" >可以拖动圆圈，点击切换状态：</div>
                <div className="border">
                    <svg className="viz" width={"100%"} height={600}></svg>
                </div>
            </div>
        </div>
    );
}

export default Dtest;