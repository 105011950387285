import React, { useEffect } from "react";
import * as d3 from "d3";


function Ddemo() {

  useEffect(() => {
    const svg = d3.select(".viz");
    svg.append("rect").attr("width", 150).attr("height", 150).style("fill", "red");
    svg.append("rect").attr("width", 130).attr("height", 130).style("fill", "blue");
    svg.insert("rect").attr("width", 100).attr("height", 100).style("fill", "yellow");

    const nodes = [{}, {}, {}];
    const simulation = d3.forceSimulation(nodes).force('charge', d3.forceManyBody()).force('center', d3.forceCenter(100, 100)).on('tick', ticked);

    function ticked() {
      d3.select('svg').selectAll('rect').data(nodes).join('rect').attr('x', function (d: any) { return d.x }).attr('y', function (d: any) { return d.y });
    };

    const dragForce: any = (simulation: any) => {
      function dragstarted(event: any, d: any) {
        if (!event.active) simulation.alphaTarget(0.3).restart();
        d.fx = d.x;
        d.fy = d.y;
      }
      function dragged(event: any, d: any) {
        d.fx = event.x;
        d.fy = event.y;
      }
      function dragended(event: any, d: any) {
        if (!event.active) simulation.alphaTarget(0);
        d.fx = null;
        d.fy = null;
      }
      return d3.drag()
        .on("start", dragstarted)
        .on("drag", dragged)
        .on("end", dragended);
    };

    d3.select("svg").selectAll("rect").call(dragForce(simulation));
  }, []);


  return (
    <div>
      <div className="container" >
        
        <div className="my-2" >可以拖动的方块，并且互相有排斥力：</div>
        <div className="border">
          <svg className="viz border" width={"100%"} height={600}></svg>
        </div>
      </div>
    </div>
  );
}

export default Ddemo;