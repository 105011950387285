import React, { useState, useEffect, useContext } from 'react';
import { Outlet, Link } from 'react-router-dom';
import { useParams } from 'react-router-dom'
import { BeatLoader } from "react-spinners";
import CartItem from './CartItem';
import ItemShort from './ItemShort';
import { CartContext } from './CartContext';


export default function ItemDetail() {
    const { id }: any = useParams();
    const [item, setItem] = useState({
        id: null,
        title: '',
        price: '',
        category: '',
        description: '',
        image: ''
    });
    const [priceinfo, setPriceinfo] = useState('');
    const [loading, setLoading] = useState(true);
    const { aList, bList, setBList, sum, setSum  } = useContext(CartContext);

    const addCart = () => {
        var it = Number(sessionStorage.getItem(`id${id}`));
        if (isNaN(it)) {
            it = 1;
        } else {
            it = it + 1;
        };
        sessionStorage.setItem(`id${id}`, String(it));
        
        let itList: any = [];
        var cartkey = Object.keys(sessionStorage)
        for (var key of cartkey) {
            if (sessionStorage[key as keyof typeof sessionStorage] === 0) {
            } else {
                for (let it of aList) {
                    if (key === `id${it['id']}`) {
                        const num = { number: sessionStorage[key as keyof typeof sessionStorage] };
                        const sub = { sub: sessionStorage[key as keyof typeof sessionStorage] * it['price'] }
                        it = Object.assign({}, it, num, sub);
                        itList.push(it);
                    }
                }
            }
        }
        let k = 0;
        for (let it of itList) {
            k = k + it['sub']
        };
        setSum(k);
        setBList(itList);
    };

    useEffect(() => {
        fetch(`https://fakestoreapi.com/products/${id}`)
            .then(res => res.json())
            .then(json => {setItem(json); return json})
            .then(json => setPriceinfo(`价格：${json.price}元`))
            .then(()=>setLoading(false));
    }, []);

    return (
        <div className='my-2 ' >
            <CartItem  />
            <div className='my-2 ' >这里是 {id} 号商品的详情...</div>
            <BeatLoader loading={loading} cssOverride={{marginTop:'2rem',marginBottom:'2rem',}} />
            <div className='card border-0 ' >
                <div className='d-flex justify-content-center' >
                    <img src={item.image} className="card-img-top px-2 my-2 " style={{ width: 'auto', maxWidth: '100%', maxHeight: '30rem' }} alt={item.title}></img>
                </div>
                <div className="card-body">
                    <button className='btn btn-primary btn-sm' onClick={() => addCart()} >放入购物车</button>
                    {/* <div className='card-title my-1 fs-6 text-muted text-opacity-25 ' >{item.id} 号商品：</div> */}
                    <div className='my-1 text-muted text-opacity-25 ' >{item.title}</div>
                    <div className='my-1 text-info ' >{priceinfo}</div>
                    <div className='my-1 text-muted text-opacity-25 ' >{item.category}</div>
                    <div className='my-2' >{item.description}</div>
                </div>
                {/* <div style={{height:'0px'}} >{itemList}</div> */}
            </div>
            <Link to='/shop' className='my-3 fs-5 text-primary d-flex justify-content-center '> &#x1F449; 回到网店首页 &#x1F448; </Link>
        </div>
    )
};