import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";


function Auth() {
    const [log, setLog] = useState(false);
    const [count, setCount] = useState(0);
    const [username, setUsername] = useState('');
    const [pw, setPw] = useState('');
    const [dbg, setDbg] = useState('');

    const counter = () => {
        // if (Number(sessionStorage[`${username}`]) === 0) {
        //     sessionStorage[`${username}`] = 1
        //     setCount(1);
        // }else{
        // sessionStorage[`${username}`] = Number(sessionStorage[`${username}`]) + 1;
        // setCount(Number(sessionStorage[`${username}`]));
        // }
        setCount(count+1)
    };

    const login = (e:any) => {
        e.preventDefault();

        const formdata = new FormData();
        formdata.append("username",username)
        formdata.append("password",pw)
       
        fetch('https://toocomplex.top/accounts/api/', {
            method:'POST',           
            mode:'cors',
            // headers:{'withCredentials':'true'},
            // headers: {'Content-Type': 'application/',},
            credentials:'include',
            body: formdata,
        }).then(res => res.text()).then((text) => {
            console.log(text); 
            if (text === 'good') {
            setLog(true);
            fetch('https://toocomplex.top/counttest/', {method:'GET', credentials:'include', mode:'cors'}).then(res => res.json()).then(res => {setDbg(JSON.stringify(res)); console.log(res.count); if(res.count){setCount(res.count)}})
            }
        })

        // if (pw) {
        //     setLog(true);
        //     if (isNaN(sessionStorage[`${username}`])) {
        //         sessionStorage[`${username}`] = 0
        //         setCount(0)
        //     }else{
        //     setCount(Number(sessionStorage[`${username}`]));
        //     }
        // }else{
        // }
    };

    const logout = () => {
        const data = {usercount:count}
        const formdata = new FormData()
        formdata.append("usercount", count.toString())
        fetch('https://toocomplex.top/counttest/',{
            method:'POST',
            mode:'cors',
            credentials:'include',
            // headers:{ContentType:'application/json'},
            // body:JSON.stringify(data),
            body:formdata,
        }).then(res => res.text()).then(
            res => {console.log(res);
            // fetch('https://toocomplex.top/accounts/logout/')
            let xhr = new XMLHttpRequest();
            xhr.open('GET', 'https://toocomplex.top/accounts/logout/');
            xhr.withCredentials = true;
            xhr.send();
            }
        );
        setLog(false);
        setUsername('');
        setPw('')
    };

    const unfunc = (e:any) => {
        setUsername(e.target.value)
    };

    const pwfunc = (e:any) => {
        setPw(e.target.value)
    };

    const tokenlogin = (e:any) => {
        console.log(username, pw)
        // const data = {username:username, password:pw}
        const formdata = new FormData()
        formdata.append("username", username)
        formdata.append("password", pw)
        fetch('https://dataocean.club/api/token/',{
            method:'POST',
            mode:'cors',
            // credentials:'include',
            // headers:{ContentType:'application/json'},
            // body:JSON.stringify(data),
            body:formdata,
        }).then(res => res.text()).then(res => console.log(res))
    }

    // useEffect(() => {},[]);

    if (log) {
        return (
            <div className="d-flex flex-column align-items-start my-5 ">
                <div className="container mb-5 ">
                    <div className="fw-bold fs-3 mx-2 my-2 " >欢迎 {username}， </div>
                    <div className=" fs-5 mx-2 my-3 " >你点击了 {count} 次</div>
                    <button className="btn  btn-outline-primary" onClick={counter} >点击计数器</button>
                </div>
                <button className="btn btn-primary" onClick={logout} >登出</button>
                <div>
                    <p>jsonres: {dbg}</p>
                </div>
            </div>
        );
    } else {
        return (
            <div className="d-flex flex-column align-items-start my-5 ">
                <div className="fw-bold fs-3 mx-2 mb-3 " >请登录</div>
                {/* <form action="https://www.dataocean.club/wx/fakestore" > */}
                <form id="loginform" onSubmit={login} >
                    <div className="mb-3">
                        <label  className="form-label">用户名</label>
                        <input type="name"  className="form-control" onChange={unfunc}  placeholder="" />
                    </div>
                    <div className="mb-3">
                        <label  className="form-label">密码</label>
                        <input type="password"  className="form-control" onChange={pwfunc} placeholder="" />
                    </div>
                    {/* <div className="fw-bold fs-3 mx-2 my-2 " > {info} </div> */}
                    <button className="btn btn-primary my-2 me-3 " type="submit" >session登入</button>
                    <text className="btn btn-primary my-2 " onClick={tokenlogin} >token登入</text>
                    <div className="fw-lighter fs-6 mx-2 my-2 " >用户名"TestUser1"，密码"!1douser"</div>
                </form>
            </div>
        )
    };
};

export default Auth;